<template>
  <div>
    <div class="BoxHeight textc bbox">
      <img src="../../assets/success.png"
           alt=""
           width="50"
           height="50"
           class="mt40 mb40" />
      <div class="text111 fw700">
        {{ tips }}
      </div>
      <!-- 查看结果 -->
      <div v-if="'1' == isRead"
           class="textc mt40 mb15">
        <el-button type="primary"
                   @click="gotoResult"
                   class="wp80 f16 Btn">查看结果</el-button>
      </div>
      <div v-if="'0' == isRead"
           class="text666 f15"
           @click="goBack">
        返回首页
      </div>
    </div>
    <!-- 页脚 -->
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
import Footer from "../../components/footer.vue";
var tips1 = "您已成功提交试卷答案，可以立即查看试卷结果啦！";
var tips2 = "您已成功提交考试答案，请耐心等待考试结果通知！";
export default {
  // components: { Footer },
  data () {
    return {
      tips: tips1,
      isRead: "0",
      score: 0,
      examTitle: "",
    };
  },
  methods: {
    goBack () {
      this.$router.push("/index");
    },
    gotoResult () {
      this.$router.push({
        path: "/examResult",
        query: {
          score: this.score,
          examTitle: this.examTitle,
        },
      });
    },
    changeLanguage () {
      if (this.$store.getters.language == "zh") {
        this.tips1 = "您已成功提交试卷答案，可以立即查看试卷结果啦！";
        this.tips2 = "您已成功提交考试答案，请耐心等待考试结果通知！";
      }
      if (this.$store.getters.language == "en") {
        this.tips1 = "You have submitted the answers to the test paper successfully. Now you can view the results of the test paper!";
        this.tips2 = "You have submitted your answers successfully. Please wait for the results!";
      }
      if (this.$store.getters.language == "ja") {
        this.tips1 = "答案の提出に成功して、すぐ答案用紙の結果を見ることができますよ!";
        this.tips2 = "あなたは試験の解答を提出することに成功して、試験の結果の通知を辛抱強く待ってください!";
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    window.onbeforeunload = null;
    var isBack = to.path.toString().indexOf("examination");
    if (-1 == isBack) {
      next();
    }
  },
  created () {
    this.changeLanguage();
    this.isRead = this.$route.query.isRead;
    this.score = this.$route.query.score;
    this.examTitle = this.$route.query.examTitle;
    if ("0" == this.isRead) {
      this.tips = tips2;
    } else {
      this.tips = tips1;
    }
  },
  mounted () {
    this.changeLanguage();
  },
  updated () {
    this.changeLanguage();
  }
};
</script>

<style>
</style>